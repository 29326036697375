import anime from "animejs";

interface GaDataProps {
  event_label: string;
}

declare global {
  interface Window {
    gtag: any;
  }
}

export const sendGA = (action: string, data: GaDataProps) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", action, {
      event_category: "visiteur",
      ...data,
    });
  }
};

export const scrollToPosition = (top: number, duration = 600) => {
  const scrollElement =
    window.document.scrollingElement ||
    window.document.body ||
    window.document.documentElement;
  anime({
    targets: scrollElement,
    duration,
    scrollTop: top,
    easing: "easeInOutQuad",
  });
};

export const setRef = (el, array) => {
  if (el && !array.current.includes(el)) {
    array.current.push(el);
  }
};
