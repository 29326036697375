import cx from "classnames";
import React, { FC } from "react";
import { NavCta } from "../../../components/Cta";

import SEO from "../../../components/seo";
import * as global from "../../../styles/global.module.scss";
import * as quizStyles from "../../../styles/shared/quiz.module.scss";
import { sendGA } from "../../../utils/helpers";
const { routes } = require("../../../routes");
interface Props {
  data: {
    [key: string]: any;
  };
  location: Location;
}

const DiscoverStep2b: FC<Props> = (props: Props) => {
  const { location } = props;
  return (
    <>
      <SEO
        meta={[
          {
            name: "og:url",
            content: location.href,
          },
        ]}
        title="Constant - assistant shopping 100% éco-responsable"
      />
      <div className={cx(global.pageWrapper)}>
        <div className={quizStyles.introContainer}>
          <h2 className={cx(quizStyles.title, global.bigTitle, global.title)}>
            <u>La solution faite pour vous</u> : <br />
            Les guides ready-to-go.
          </h2>
          <div className={quizStyles.ctaContainer}>
            <NavCta to={routes.app.guides.path} label="Voir les guides" />
          </div>
          <p className={global.text}>
            Faites un tour sur la page des guides ready-to-go. <br /> Ce sont
            des sélections créées avec soin pour anticiper vos besoins.
          </p>
        </div>
        <div className={cx(quizStyles.otherSolutionWrapper)}>
          <div className={cx(quizStyles.container)}>
            <h3 className={cx(quizStyles.title, global.title)}>
              Vous avez déjà regardé mais n’avez pas trouvé ce qu’il vous
              fallait&nbsp;?
            </h3>
            <div className={global.text}>
              <ol>
                <li>
                  Si vous êtes pressé, ca vaut surement le coup que je vous
                  fasse un guide personalisé ! <br />
                  <NavCta
                    to={"/"}
                    label="Je veux un guide personnalisé"
                    wordingHoverClassname={quizStyles.otherSWHover}
                    btnClassname={quizStyles.otherSBtn}
                    btnHoverClassname={quizStyles.otherSHoverBtn}
                    onClick={() => {
                      sendGA("click", {
                        event_label: "cta - in a hurry",
                      });
                    }}
                  />
                </li>
                <li>
                  Si vous n’êtes pas préssé, dites moi le guide que vous
                  aimeriez voir et je vous préviendrai lorsqu'il sera
                  disponible&nbsp;: <br />
                  <a
                    className={global.link}
                    href={`mailto:${process.env.GATSBY_EMAIL}`}
                    onClick={() => {
                      sendGA("click", {
                        event_label: "email - not in a hurry",
                      });
                    }}
                  >
                    {process.env.GATSBY_EMAIL}
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverStep2b;
